<template>
    <TransitionRoot as="template" :show="showMenu">
        <Dialog as="div" class="relative z-10" @close="close()">
            <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                            leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div class="px-4 sm:px-6">
                                        <div class="flex items-center justify-between">
                                            <DialogTitle>
                                                <img class="block h-8 w-auto " src="~/assets/wetix-logo-text-navy.svg"
                                                    alt="WeTix" />
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button"
                                                    class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                    @click="close()">
                                                    <span class="sr-only">Close panel</span>
                                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                        <!-- Replace with your content -->
                                        <div class="h-full flex flex-col justify-between border-t">
                                            <div
                                                class="border-b mt-4 pb-4 flex flex-col gap-2 text-lg font-semibold text-gray-700">
                                                <NuxtLink v-for="item in links" :key="item.name" as="template"
                                                    class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                                                    :to="item.href" @click="close()" active-class="bg-gray-100">
                                                    {{ item.name }}
                                                </NuxtLink>
                                            </div>
                                            <div class="mt-4 flex flex-col gap-2 text-lg font-semibold text-gray-700">
                                                <div class="px-4 flex items-center mb-4">
                                                    <div class="flex-shrink-0">
                                                        <img class="h-10 w-10 rounded-full" :src="authState.profilePhoto"
                                                            :alt="authState.user?.name" />
                                                    </div>
                                                    <div class=" ml-3">
                                                        <div class="text-base font-medium text-gray-800">{{
                                                            authState.user?.name
                                                        }}</div>
                                                        <div class="text-sm font-medium text-gray-500">{{
                                                            authState.email
                                                        }}</div>
                                                    </div>
                                                    <div>
                                                        <NavTenantSwitcher />
                                                    </div>

                                                    <button type="button"
                                                        class="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                                        <span class="sr-only">View notifications</span>
                                                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                                <NuxtLink v-for="item in userNavigation" :key="item.name" as="template"
                                                    :to="item.href" active-class="bg-gray-100"
                                                    class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">
                                                    {{ item.name }}
                                                </NuxtLink>
                                            </div>
                                        </div>
                                        <!-- /End replace -->
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { XIcon, BellIcon, ShoppingBagIcon, HomeIcon, MailIcon, InformationCircleIcon, ArrowRightIcon, UserIcon, LoginIcon, LogoutIcon } from '@heroicons/vue/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
const props = defineProps({
    showMenu: {
        type: Boolean,
        required: true
    },
    links: {
        type: Array as any,
        required: true
    },
    userNavigation: {
        type: Array as any,
        required: true
    },
    authState: {
        type: Object as any,
        required: true
    }
})
const { showMenu, links } = toRefs(props)
const emit = defineEmits(['closeMenu'])
const close = () => {
    emit('closeMenu')
}


</script>