<template>
    <div v-if="show" class="bg-[#16278B]">
        <div class="mx-auto max-w-7xl py-1 px-1 sm:px-1 lg:px-1">
            <div class="flex flex-wrap items-center justify-between">
                <div class="flex w-0 flex-1 items-center">
                    <span class="flex rounded-lg bg-blue-800 p-1">
                        <Icon name="wetix:light" class="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                    <p class="ml-3 truncate font-light text-xs text-white">
                        <span class="md:hidden">{{ config.public['ENVIRONMENT'] }}</span>
                        <span class="hidden md:inline">[{{ config.public['ENVIRONMENT'] }}] {{ config.public['RELEASE_SHA']
                        }}</span>
                    </p>
                </div>
                <Icon v-if="auth.isLoggedIn" name="ri:admin-fill" class="h-4 w-4 text-white mr-2" aria-hidden="true" />
                <Icon v-if="auth.userIsTenantAdmin" name="material-symbols:admin-panel-settings-outline"
                    class="h-4 w-4 text-white mr-2" aria-hidden="true" />
                <span
                    class="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800 opacity-80">
                    {{
                        useActiveTenantId()
                    }}</span>
                <div class="order-3 mt-2 w-auto flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                    <!-- <a href="#"
                        class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary-600 shadow-sm hover:bg-primary-50">Learn
                        more</a> -->
                </div>
                <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                    <button type="button" @click="show = false"
                        class="-mr-1 flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
                        <span class="sr-only">Dismiss</span>
                        <Icon name="heroicons:x-mark-20-solid" class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { useAuthState } from "~/stores/auth";

const config = useRuntimeConfig();

const auth = useAuthState();

const show = ref(true)

</script>