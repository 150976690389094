<template>
    <div>
        <Menu as="div" class="relative inline-block text-left">
            <div>
                <MenuButton
                    class="bg-white  ml-4 rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                    <span class="sr-only">Open user menu</span>
                    <img v-if="profilePhotoIsLoading || error" class="h-8 w-8 rounded-full"
                        src="/images/profile-photo-placeholder.png" alt="U" />
                    <img v-else class="h-8 w-8 rounded-full" :src="authState.profilePhoto" alt="" />
                </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="px-4 py-3">
                        <p class="text-sm">Signed in as</p>
                        <p class="text-sm font-medium text-gray-900 truncate">{{ authState.email }}</p>
                    </div>
                    <div class="py-1">
                        <MenuItem v-slot="{ active }">
                        <NuxtLink to="/app/user/profile"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                            Account
                            settings</NuxtLink>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                        <NuxtLink to="/app/tenant-admin/help"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                            Support</NuxtLink>
                        </MenuItem>

                    </div>
                    <div class="py-1">

                        <MenuItem v-slot="{ active }">
                        <button @click="$signOut()"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">Sign
                            out</button>
                        </MenuItem>

                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script setup lang="ts">
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { useImage } from '@vueuse/core'


// Auth state
import { useAuthState } from "~/stores/auth";
const authState = useAuthState()

const { $signOut } = useNuxtApp()

const { isLoading: profilePhotoIsLoading, error } = useImage({ src: authState.profilePhoto })


</script>

